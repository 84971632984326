import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-open-image-modal',
  templateUrl: './open-image-modal.component.html',
  styleUrls: ['./open-image-modal.component.scss']
})
export class OpenImageModalComponent {
  base64Img?: string;
  imgFile!: SafeResourceUrl;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) { 
  }

  ngOnInit() {
    if (this.data.imagen.deliveryFileTypeId == 2) {
      this.saveBase64AsImg(this.data);
    }
  }

  saveBase64AsImg(json: any): void {
    var base64String = json.imagen.base64Data.split(';base64,').pop();
    this.imgFile = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + base64String);
  }

}
