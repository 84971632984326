<div id="loadingView">
  <div class="loadingBackground"></div>
  <div class="loader">Loading...</div>
</div>
<div class="h-100 row noXMargin pageBody">
  <div class="col-12">
    <div>
      <div class="row">
        <app-header></app-header>
      </div>
      <div class="row main-container-modify">
        <div id="componentContent">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div class="row">
      <app-footer></app-footer>
    </div>
  </div>
</div>
