import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { DeliveryAttachedFiles } from '../../models/delivery-attached-files';
import { DeliveryAttachedFilesService } from '../../services/delivery-attached-files.service';
import swal from 'sweetalert2';
import { DeliveryInfoService } from '../../services/delivery-info.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage!: WebcamImage | null;
  captureImage: string | null = null;
  jsonData: any[] = [];
  deliveryInfo: any;

  constructor(private dialog: MatDialog,
    private _deliveryAttachedFilesService: DeliveryAttachedFilesService,
    private _deliveryInfoService: DeliveryInfoService,
    private _pageLoadingService: PageLoadingService) {

  }

  ngOnInit() {
    this._deliveryInfoService.deliveryInfo$.subscribe(data => {
      this.deliveryInfo = data;
    });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.captureImage = webcamImage.imageAsDataUrl;
  }

  public cancelCapture(): void {
    this.captureImage = null;
    this.webcamImage = null;
  }

  async saveImage() {
    this.dialog.closeAll();
    this._pageLoadingService.showLoadingGif();
    if (this.captureImage) {

      //Metodo que genera la rotacion de la imagen a vertical 
      const rotatedImage = await this.rotateImage(this.captureImage);
      this.captureImage = rotatedImage;



      const base64Data = this.captureImage;
      const type = 'image';
      const fileTypeId = 2;
      const mimeType = this.captureImage.split(';')[0].split(':')[1];
      const extension = mimeType.split('/')[1];
      const fileName = `img_${Date.now()}.${extension}`;

      if (typeof base64Data === 'string') {
        const jsonData: DeliveryAttachedFiles = {
          base64Data: base64Data,
          deliveryFileTypeId: fileTypeId,
          fileName: fileName,
          deliveryNumber: this.deliveryInfo.deliveryNumber
        };

        this._deliveryAttachedFilesService.saveDeliveryAttachedFile(jsonData).subscribe(data => {
          // return "Entrega creada";
          this.jsonData.push(jsonData);
          this._pageLoadingService.hideLoadingGif();
          swal.fire({
            icon: 'success',
            title: '¡Excelente!',
            text: 'La imagen se ha guardado correctamente!',
            confirmButtonColor: '#012864',
          });
        }, error => {
          this._pageLoadingService.hideLoadingGif();
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible guardar la imagen correctamente',
            confirmButtonColor: '#012864',
          })
        })
      } else {
        console.error("Error al obtener el contenido base64.");
        this.dialog.closeAll();
      }
    }

  }


  // Función para rotar la imagen si es horizontal
  rotateImage = (base64Data: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {

        const isHorizontal = img.width > img.height;
        if (isHorizontal) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;

          // Configurar el tamaño de la imagen dandole las mismas medidas invertidas
          canvas.width = img.height;
          canvas.height = img.width;

          // Aplicar la rotación de la imagen
          ctx.transform(0, 1, -1, 0, img.height, 0);

          // Dibujar la imagen en la posicion 0
          ctx.drawImage(img, 0, 0);

          // Obtener la nueva imagen como base64
          const rotatedBase64Data = canvas.toDataURL('image/jpeg');
          resolve(rotatedBase64Data);
        } else {
          const rotatedBase64Data = base64Data;
          resolve(rotatedBase64Data);
        }
      };
      img.onerror = reject;
      img.src = base64Data;
    });
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
}
