
<div id="">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="deliveryNumber">
              <th mat-header-cell *matHeaderCellDef>N°.ENTREGA</th>
              <td mat-cell *matCellDef="let delivery">{{ delivery.deliveryNumber }}</td>
            </ng-container>
          
            <ng-container matColumnDef="salesOrder">
              <th mat-header-cell *matHeaderCellDef>N°.ORDEN SAP</th>
              <td mat-cell *matCellDef="let delivery">{{ delivery.salesOrder }}</td>
            </ng-container>

            <ng-container matColumnDef="custPoNum">
              <th mat-header-cell *matHeaderCellDef>N°.ORDEN </th>
              <td mat-cell *matCellDef="let delivery">{{ delivery.custPoNum }}</td>
            </ng-container>

            <ng-container matColumnDef="institutionCode">
              <th mat-header-cell *matHeaderCellDef>COD.INSTITUCIÓN</th>
              <td mat-cell *matCellDef="let delivery">{{ delivery.institutionCode }}</td>
            </ng-container>
          
            <ng-container matColumnDef="institutionName">
              <th mat-header-cell *matHeaderCellDef>INSTITUCIÓN</th>
              <td class="institutionCellCustom" mat-cell *matCellDef="let delivery">{{ delivery.institutionName }}</td>
            </ng-container>
          
            <ng-container matColumnDef="remisionGuideNumber">
              <th mat-header-cell *matHeaderCellDef>N°.GUÍA</th>
              <td mat-cell *matCellDef="let delivery">{{ delivery.remisionGuideNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="statusLog">
              <th mat-header-cell *matHeaderCellDef>ULT. ESTADO ENTREGA</th>
              <td mat-cell class="statusLogCustom" *matCellDef="let delivery">
                <b *ngIf="delivery.statusLog?.status?.eventName == 'En camino'" class="onTheWay">{{ delivery.statusLog?.status?.eventName }}</b>
                <b *ngIf="delivery.statusLog?.status?.eventName == 'Entregado'" class="delivered">{{ delivery.statusLog?.status?.eventName }}</b>
                <b *ngIf="delivery.statusLog?.status?.eventName == 'Rechazado'" class="refused">{{ delivery.statusLog?.status?.eventName }}</b>
                <b *ngIf="delivery.statusLog?.status?.eventName == null">--</b>
              </td>
            </ng-container>
          
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>FECHA</th>
              <td mat-cell class="dateCellCustom" *matCellDef="let delivery">{{ delivery.statusLog?.statusUpdateTime | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
            </ng-container>
          
            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> ACCIONES </th>
              <td mat-cell *matCellDef="let delivery">
                <button (click)="downloadPDF(delivery.deliveryNumber)" class="btnDelivery" data-toggle="tooltip" data-placement="top" title="Descargar archivos de soporte">
                  <!-- <i class="bi bi-download icon-download-modify"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/></svg>
                </button>
                  <button *ngIf="isAdmin" (click)="deliveryInfo(delivery.deliveryNumber)" class="btnDelivery" data-toggle="tooltip" data-placement="top" title="Editar entrega">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>
                  </button>
              </td>
          </ng-container>
          <tr mat-header-row class="highlight-row" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: ['deliveryNumber', 'salesOrder', 'custPoNum', 'institutionCode', 'institutionName', 'remisionGuideNumber', 'statusLog', 'status', 'options']; let even = even;" [ngClass]="{'even-row': even, 'odd-row': !even}" ></tr>
          
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data-centered" colspan="12">Sin Entregas disponibles</td>
          </tr> 

        </table>

        <mat-paginator [pageSize]="10" [length]="deliveriesTableLength" showFirstLastButtons aria-label="Select page of periodic elements" (page)="pageIndexUpdated($event)">
        </mat-paginator>

    </div>
</div>
<!-- Delivery events modal - END -->


