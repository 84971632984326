<div class="container-fluid mb-5" id="prueba">
  <div class="mb-5">
    <h2>Entregas</h2>
    <hr />
  </div>
  <div class="row">
    <div class="col-md-9">
      <app-orders-filters
        (searchBarModifiedEvent)="applyFilter($event)"
        (advancedSearchFiltersModified)="applyAdvancedFilters($event)"
      >
      </app-orders-filters>
    </div>

    <div class="col-md-3">
      <app-data-export class="row positionButtonDataExport"> </app-data-export>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-deliveries-table
        (DataExportEvent)="getDataExport($event)"
        (isReadyEvent)="getIsReadyData($event)"
      >
      </app-deliveries-table>
    </div>
  </div>
</div>
