import { Injector } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { FieldError } from "../errors/error.model";
import { PageLoadingService } from "src/app/shared/services/page-loading.service";
import { SnackBarService } from "src/app/shared/services/snackbar.service";

/**
 * Base class for components with one main form.
 */
export class SubmitFormBase {
  fieldErrors: FieldError[] = [];
  isTriedToSubmitMainForm: boolean = false;
  loginData: FormGroup = {} as FormGroup;

  /**
   * Reset form field incorrect state when value be updated.
   * @param {string} fieldKey : form field id
   */
  protected removeErrorsOnChange = (fieldKey: string) => {
    const indexToDelete = this.fieldErrors.findIndex(e => e.key === fieldKey);
    this.fieldErrors.splice(indexToDelete, 1);
  };

  protected _formBuilder: FormBuilder;
  protected _pageLoadingService: PageLoadingService;
  protected _router: Router;
  protected _snackbarService: SnackBarService;

  constructor(injector: Injector) {
    this._formBuilder = injector.get(FormBuilder);
    this._pageLoadingService = injector.get(PageLoadingService);
    this._router = injector.get(Router);
    this._snackbarService = injector.get(SnackBarService);
  }

  /**
   * Get form first error.
   * @param {string} key : form field id
   * @returns {string} return the first form error message
   */
  getFirstFieldError(key: string) {
    return this.fieldErrors.find(e => e.key === key)?.errors[0];
  }

  /**
   * Validate if a form control of form has errors.
   * @param {string} key : form field id
   * @returns {bolean} return if the form field has errors
   */
  fieldHasErrors(key: string) {
    return this.fieldErrors.some(e => e.key === key) ||
      (this.loginData.controls[key].invalid &&
        (this.loginData.controls[key].dirty || this.isTriedToSubmitMainForm));
  }

  /**
   * Restart form control valid state when its value be updated.
   */
  configureFormFieldsForErrorRestart() {
    Object.keys(this.loginData.controls).forEach((c) => {
      this.loginData.get(c)?.valueChanges.subscribe(newValue => {
        this.removeErrorsOnChange(c);
      })
    });
  }
}
