import { Component, Injector, ViewChild } from '@angular/core';
import { DataExportComponent } from '../data-export/data-export.component';
import { OrdersFiltersComponent } from '../orders-filters/orders-filters.component';
import { DeliveriesTableComponent } from '../deliveries-table/deliveries-table.component';
import { OrdersFilter } from '../../models/orders-filter.model';
import { Delivery } from '../../models/delivery.model';

@Component({
  selector: 'app-orders-main-view',
  templateUrl: './orders-main-view.component.html',
  styleUrls: ['./orders-main-view.component.scss']
})
export class OrdersMainViewComponent {
  @ViewChild(DataExportComponent) dataExport: DataExportComponent | undefined;
  @ViewChild(OrdersFiltersComponent) ordersFilters: OrdersFiltersComponent | undefined;
  @ViewChild(DeliveriesTableComponent) deliveriesTable!: DeliveriesTableComponent;

  getDataExport(event: any) {    
    this.dataExport!.dataExportArray = event;
  }

  getIsReadyData(event: any){
    this.dataExport!.isReady = event;    
  }

  deliveriesUpdated(event: any) {
    const orderStateFilter = this.ordersFilters!.filters.filter((orf) => {
      return orf.name === 'orderType';
    })[0];

    if (
      orderStateFilter != null &&
      orderStateFilter != undefined &&
      orderStateFilter.control.value != null &&
      orderStateFilter.control.value != undefined
    ) {
      this.deliveriesTable!.dataSource.data =
        this.deliveriesTable!.dataSource.data.filter((o: any) => o.status.statusId == 0);
    }
  }

  applyFilter(event: any) {
    this.deliveriesTable!.dataSource.filter = (event as string).trim().toLowerCase();

    if (this.deliveriesTable!.dataSource.paginator) {
      this.deliveriesTable!.dataSource.paginator.firstPage();
    }
  }

  applyAdvancedFilters(event: any) {
    const filters = event as OrdersFilter[];
    const filtersWithValue = filters.filter(
      (f) => f.control.value != null && f.control.value != undefined
    );

    if (filtersWithValue && filtersWithValue.length > 0) {
      let filteredOrders: Delivery[] = this.deliveriesTable.currentDeliveries;

      filtersWithValue.forEach((f) => {
        filteredOrders = f.filterFunction(filteredOrders, f.control.value);
      });

      this.deliveriesTable!.dataSource.data = filteredOrders;
    } else {
      this.deliveriesTable!.dataSource.data = [...this.deliveriesTable.currentDeliveries];
    }
  }

}
