import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditStateDeliveryComponent } from '../edit-state-delivery/edit-state-delivery.component';
import { DeliveryAttachedFiles } from '../../models/delivery-attached-files';
import { DeliveryAttachedFilesService } from '../../services/delivery-attached-files.service';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { OpenImageModalComponent } from '../open-image-modal/open-image-modal.component';
import swal from 'sweetalert2';

import { Content } from 'pdfmake/interfaces';
import { DeliveryInfoService } from '../../services/delivery-info.service';
import { StatusLogService } from '../../services/status-log.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';

type ImageContent = Content & {
  image: Blob;
  width: number;
}

@Component({
  selector: 'app-edit-delivery',
  templateUrl: './edit-delivery.component.html',
  styleUrls: ['./edit-delivery.component.scss']
})

export class EditDeliveryComponent implements OnInit, AfterViewInit {
  imagen: string = '';
  nameImagen: string = '';
  imagenName: string[] = [];
  jsonData: any[] = [];
  imageSource: any;
  imgFile: any;
  arrayImage: DeliveryAttachedFiles[] | undefined = [];
  deliveryInfo: any;
  panelOpenState: boolean = false;
  institutionCodeParse?: string;
  @ViewChild('fileInput') fileInput!: ElementRef;

  bucleFinalizado: boolean = false;
  ejecutarToBucleFinalizate: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _deliveryAttachedFilesService: DeliveryAttachedFilesService,
    private _statusLogService: StatusLogService,
    private _pageLoadingService: PageLoadingService,
    private _deliveryInfoService: DeliveryInfoService
  ) {
    // this._pageLoadingService.showLoadingGif();
  }

  ngOnInit() {
    this._pageLoadingService.showLoadingGif();
    this._deliveryInfoService.deliveryInfo$.subscribe(data => {
      this.deliveryInfo = data;

      if (this.deliveryInfo.soldTo != null) {
        this.institutionCodeParse = this.deliveryInfo.soldTo.substring(4);
      }
    });

    if (this.deliveryInfo == null) {
      this._pageLoadingService.hideLoadingGif();
    }

    // this._deliveryAttachedFilesService.initializeImages(this.arrayImage, this.deliveryInfo.deliveryNumber);
    // Suscríbete al observable para actualizar this.arrayImage cuando cambie

  }

  ngAfterViewInit() {
    this._deliveryAttachedFilesService.initializeImages(this.arrayImage, this.deliveryInfo.deliveryNumber);

    this._deliveryAttachedFilesService.getImages$().subscribe(images => {
      this.arrayImage = images;
      this.bucleFinalizado = this.arrayImage?.length === 0;
    });

    this.fileInput.nativeElement.addEventListener('click', () => {
      // Reiniciar el valor del campo de entrada de archivos
      this.fileInput.nativeElement.value = '';
      this.ejecutarToBucleFinalizate = true;
    });
  }

  toBucleFinalizate(last: any) {
    if (last) {
      this._pageLoadingService.hideLoadingGif();
    } else {
      this._pageLoadingService.showLoadingGif();
    }
  }

  backTo() {
    this.router.navigate(['/deliveries']);
  }


  openModal(deliveryInfoService: DeliveryInfoService, invoice: any, action: string, index: number) {

    this.ejecutarToBucleFinalizate = true;
    this.dialog.open(EditStateDeliveryComponent, {
      // width: '800px', // Puedes ajustar el ancho según tus necesidades
      width: '800px',
      height: 'auto',
      data: [deliveryInfoService, invoice, action, index]
    });
  }

  openModalImg() {
    this.ejecutarToBucleFinalizate = true;
    this.dialog.open(ImageModalComponent, {
      width: '800px', // Puedes ajustar el ancho según tus necesidades
      height: 'auto',
    });

  }

  openImage(imagen: any) {
    const dialogRef = this.dialog.open(OpenImageModalComponent, {
      width: '800px',
      data: { imagen } // Pasar la imagen como dato
    });
  }

  deleteStatus(statusId: number) {
    this.ejecutarToBucleFinalizate = true;
    swal.fire({
      title: '¿Estás seguro de que quieres eliminar el estado de entrega?',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#012864',
      cancelButtonColor: '#DF181B',
    }).then((result) => {
      if (result.isConfirmed) {
        this._pageLoadingService.showLoadingGif();
        this._statusLogService.deleteStatusLog(statusId).subscribe(data => {
          if (data) {
            this._deliveryInfoService.getDeliveryInfo(this.deliveryInfo.deliveryNumber).subscribe(data => {
              //Enviar informacion al get-deliveries
              this._deliveryInfoService.setDeliveryInfo(data);

              this.router.navigate(['/deliveries/edit']);
              this._pageLoadingService.hideLoadingGif();

            }, error => {
              console.log(error);
              this._pageLoadingService.hideLoadingGif();
              this.router.navigate(['/']);
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible encontrar la información de la entrega',
                confirmButtonColor: '#012864',
              })
            })

            swal.fire({
              icon: 'success',
              title: '¡Excelente!',
              text: 'El estado de entrega fue eliminado de manera exitosa!',
              confirmButtonColor: '#012864',
            });
          }
        }, error => {
          console.log(error);
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible Eliminar el estado de entrega',
            confirmButtonColor: '#012864',
          });
          this._pageLoadingService.hideLoadingGif();
        })
      }
    })
  }

  async selectImg(event: any) {
    this.ejecutarToBucleFinalizate = true;
    const files = event.target.files;

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.imgFile = file;

        try {
          var base64 = await this.readImageAsync(this.imgFile);

          //Metodo que genera la rotacion de la imagen a vertical 
          const rotatedImage = await this.rotateImage(base64);
          base64 = rotatedImage;

          this._pageLoadingService.showLoadingGif();
          const type = 'image';
          const fileTypeId = 2;
          const mimeType = this.imgFile.type;
          const extension = mimeType.split('/')[1];
          const fileName = `img_${Date.now()}.${extension}`;

          if (typeof base64 === 'string') {
            const jsonData: DeliveryAttachedFiles = {
              base64Data: base64,
              deliveryFileTypeId: fileTypeId,
              fileName: fileName,
              deliveryNumber: this.deliveryInfo.deliveryNumber,
            };

            await this._deliveryAttachedFilesService.saveDeliveryAttachedFile(jsonData).toPromise();
            this._pageLoadingService.hideLoadingGif();
            swal.fire({
              icon: 'success',
              title: '¡Excelente!',
              text: 'La imagen se ha guardado correctamente!',
              confirmButtonColor: '#012864',
            });

            // Después de guardar la imagen, actualizar la lista de imágenes
            this._deliveryAttachedFilesService.getImages$().subscribe(
              (imagenes) => {
                this.arrayImage = imagenes;
              }
            );
          } else {
            this._pageLoadingService.hideLoadingGif();
            console.error("Error al obtener el contenido base64.");
          }
        } catch (error) {
          this._pageLoadingService.hideLoadingGif();
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible cargar o guardar la imagen correctamente',
            confirmButtonColor: '#012864',
          });
          console.error("Error al cargar o guardar la imagen:", error);
        }
      }
    }
  }

  
  // Función para rotar la imagen si es horizontal
  rotateImage = (base64Data: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {

        const isHorizontal = img.width > img.height;
        if (isHorizontal) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;

          // Configurar el tamaño de la imagen dandole las mismas medidas invertidas
          canvas.width = img.height;
          canvas.height = img.width;

          // Aplicar la rotación de la imagen
          ctx.transform(0, 1, -1, 0, img.height, 0);

          // Dibujar la imagen en la posicion 0
          ctx.drawImage(img, 0, 0);

          // Obtener la nueva imagen como base64
          const rotatedBase64Data = canvas.toDataURL('image/jpeg');
          resolve(rotatedBase64Data);
        } else {
          const rotatedBase64Data = base64Data;
          resolve(rotatedBase64Data);
        }
      };
      img.onerror = reject;
      img.src = base64Data;
    });
  }

  async readImageAsync(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        resolve(reader.result as string);
      };
      reader.onerror = function (event) {
        reject(new Error("Error al cargar el archivo."));
      };
      reader.readAsDataURL(file);
    });
  }

  deleteFile(file: any) {
    this.ejecutarToBucleFinalizate = true;
    swal.fire({
      title: '¿Estás seguro de que quieres eliminar esta imagen?',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#012864',
      cancelButtonColor: '#DF181B',
    }).then((result) => {
      if (result.isConfirmed) {
        this._pageLoadingService.showLoadingGif();
        if (file.fileName != null) {
          this._deliveryAttachedFilesService.deleteDeliveryAttachedFile(file.fileName, this.deliveryInfo.deliveryNumber).subscribe(() => {
            swal.fire({
              icon: 'success',
              title: '¡Excelente!',
              text: 'La imagen se ha eliminado correctamente!',
              confirmButtonColor: '#012864',
            });
            this._pageLoadingService.hideLoadingGif();
          }, error => {
            swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'No fue posible Eliminar la imagen correctamente',
              confirmButtonColor: '#012864',
            });
            this._pageLoadingService.hideLoadingGif();
          });
        }
      }
    });
  }

  downloadPDF(deliveryNumber: string) {
    this._pageLoadingService.showLoadingGif();
    this._deliveryAttachedFilesService.downloadPdf(deliveryNumber)
      .subscribe((result) => {
        if (result.error) {
          // Maneja el error aquí, por ejemplo, muestra un mensaje de error al usuario.
          this._pageLoadingService.hideLoadingGif();
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible descargar el pdf porque la entrega no cuenta con archivos de soporte',
            confirmButtonColor: '#012864',
          })
          console.error('Error al descargar el PDF:', result.error);
        } else {
          this._pageLoadingService.hideLoadingGif();
          // Maneja la descarga exitosa aquí, por ejemplo, abrir el PDF en una nueva ventana.
          const pdfBlob = result.blob;
          if (pdfBlob) {
            const pdfUrl = window.URL.createObjectURL(pdfBlob);

            //Crea un enlace invisible y simula un clic para descargar el PDF
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `${deliveryNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.open(pdfUrl, '_blank');
          } else {
            console.error('El archivo PDF está vacío');
          }
        }
      });
  }

}
