
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { Delivery } from '../models/delivery.model';
import { environment  as env} from 'src/app/environments/environment';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { DataExport } from '../models/DataExport.models';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';


@Injectable({

  providedIn: 'root'

})

export class DeliveryService {

  private endpoint = "Delivery/"

  constructor(
    private http: HttpClient,
    private _cookieTokenService: CookieTokenService,
    private _pageLoadingService: PageLoadingService) { }

  getListDeliveries(){
    return this.http.get(`${env.url_api}/` + this.endpoint);

  }

  saveDelivery(delivery: Delivery): Observable<any> {

    return this.http.post(`${env.url_api}/` + this.endpoint, delivery);

  }

  getDelivery(id: number): Observable<any> {

    return this.http.get(`${env.url_api}/` + this.endpoint + id);

  }

  deleteDelivery(id: number): Observable<any> {

    return this.http.delete(`${env.url_api}/` + this.endpoint + id);

  }

  updateDelivery(id: number, delivery: Delivery): Observable<any> {

    return this.http.put(`${env.url_api}/` + this.endpoint + id, delivery);

  }

  /**
   * Genrate excel delivery.
   */

  generateDataExport(deliveriesArray: any) {
    this._pageLoadingService.showLoadingGif; 

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);    

    return this.http.post(`${env.url_api}/DataExport/export`, deliveriesArray, {responseType: 'blob', headers: headers })
  }


}


