import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsHelper } from 'src/app/shared/helpers/forms.helper';
import { LoginService } from '../../services/login.service';
import { CommonResponse } from 'src/app/models/http/response.model';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { SubmitFormBase } from 'src/app/models/forms/submit-form-base.class';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent extends SubmitFormBase implements OnInit, OnDestroy {

  readonly loginObserver = {
    next: (data: CommonResponse<any>) => this.loginNext(data),
    error: (error: CommonResponse<any>) => this.loginError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };


  constructor(
    private _loginService: LoginService,
    private _userService: UserService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loginData = this._formBuilder.group({
      userEmail: this._formBuilder.control('', { validators: [Validators.required, Validators.email] }),
      password: this._formBuilder.control('', { validators: [Validators.required],}),
    });
    
    this.configureFormFieldsForErrorRestart();

    $('.main-container-modify').css({ 'height': 'auto', 'overflow-y': 'hidden' });

    this.loginData.reset();
  }

  ngOnDestroy() {
    $('.main-container-modify').css({ 'height': '69vh', 'overflow-y': 'scroll' });
  }

  private loginNext(data: CommonResponse<any>) {
    if (ResponseHelper.responseDontHaveErrors(data)) {
      const defaultRoute: string[] | undefined = this._userService.getErrorCaseUrlForReturn() ?? ['/auth'];
      this._router.navigate(defaultRoute);
    }
    else {
      this._snackbarService.openSnackBar(data.message?.toString() ?? "Default message");
    }
  }

  private loginError(error: CommonResponse<any>) {
    this.loginData.reset();
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openSnackBar("No fue posible iniciar sesión");
    this.fieldErrors = ResponseHelper.generateFieldErrorArrayFromResponse(error, this.loginData);
  }

  login() {
    this.isTriedToSubmitMainForm = true;

    if (this.loginData.valid) {
      this._pageLoadingService.showLoadingGif();

      this._loginService.login(this.loginData.controls["userEmail"].value, this.loginData.controls["password"].value)
        .subscribe(this.loginObserver);
    }
    else {
      // this.loginData.reset();
    }
  }
}
