import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';
import { LoginService } from 'src/app/auth/services/login.service';

/**
 * Service for related system user operations.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient,
    private _cookieTokenService: CookieTokenService,
    private _loginService: LoginService) { }

 
  getErrorCaseUrlForReturn() {
    const user = this._loginService.getLoggedUser();
    let url;
    
    if(user) {
      if(user.roles.some((r: string) => r === "Admin" || r === "Consultant"))
        url = ['/deliveries'];
      
    }
    else {
      url = ['/auth'];
    }

    return url;
  }

}
