<div class="d-flex align-items-center">
  <mat-form-field class="w-50 custom-mat-form-field">
    <mat-label><i class="bi bi-search search-ico"></i>&nbsp;&nbsp;&nbsp;Buscar</mat-label>
    <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)" placeholder="Ej. 0595741644" #input>
  </mat-form-field>

  <div class="ml-3 mb-3 advancedSearch">
    <a class="advancedSearchMain" data-bs-toggle="collapse" href="#filterCollapse" role="button" aria-expanded="false" aria-controls="filterCollapse">
      Búsqueda avanzada
    </a>
  </div>
</div>

  <div class="collapse" id="filterCollapse">
    <div class="d-flex flex-row">
      <div class="me-2" *ngFor="let filter of filters; let i = index">
        <ng-container *ngIf="isSelectType(filter.type)">
          <mat-form-field class="custom-mat-form-field" appearance="fill">
            <mat-label><i [class]="filter.icoClass"></i>&nbsp;&nbsp;&nbsp;{{filter.label}}</mat-label>
            <mat-select [formControl]="filter.control">
              <mat-option>Ninguno</mat-option>
              <mat-option *ngFor="let option of filter.validValues" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="isDateType(filter.type)" readonly>
          <mat-form-field appearance="fill">
            <mat-label>{{filter.label}}</mat-label>
            <input matInput [matDatepicker]="picker" [max]="maxValidDate" [formControl]="filter.control" readonly>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matPrefix [for]="picker">
              <mat-icon matDatepickerToggleIcon><i [class]="filter.icoClass"></i></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <br>
      </div>
      <div class="ml-3 mb-3 cleanfilter">
        <a class="advancedSearchMain" role="button" (click)="cleanFilter($event)">
          Limpiar
        </a>
    </div>
    </div>
  </div>
  <br>
  
