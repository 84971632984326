// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';

const moment = _moment;

export class DatesHelper {

  static getDefaultDateFormattedString(date: Date) : string {
    let formattedMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;

    return `${date.getDate()}/${formattedMonth}/${date.getFullYear()}`;
  }

  static getDefaultMomentFormattedString(date: Date) : string {
    return moment(date).format('DD/MM/YYYY');
  }

  static isValidDate(date: Date) {
    const minDate = new Date('1900-01-02T00:00:00');

    return date > minDate;
  }
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

