<div class="header-modal-edit-state">
  <i class="bi bi-pencil-fill colorIconAction"></i
  ><b class="modalTitleModify">CÁMARA</b>
  <i class="bi bi-x bi-x-modify " mat-dialog-close></i>
</div>
<h6 class="title-modal-content">
  Puedes editar los campos o agregar un estado de entrega
</h6>
<mat-dialog-content>
  <div class="mainContainer">
    <div class="containerCamera">
      <div class="camera-wrapper">
        <ng-container *ngIf="!captureImage; else capturedImage">
          <webcam
            [height]="400"
            [width]="700"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
          ></webcam>
          <div class="containerButtonCamera">
            <button class="saveState" (click)="saveImage()" mat-raised-button>Guardar</button>
            <button class="buttonCamera" (click)="triggerSnapshot()" data-toggle="tooltip" data-placement="top" title="Capturar imagen">
              <i class="bi bi-camera-fill"></i>
            </button>
            <button mat-button mat-dialog-close>Cerrar</button>
          </div>
        </ng-container>
        <ng-template #capturedImage>
          <img *ngIf="captureImage" [src]="captureImage" alt="Captured Image" [height]="400" [width]="520"/>
          <div class="containerButtonCamera">
            <button class="saveState" (click)="saveImage()" mat-raised-button>Guardar</button>
            <button class="btn btn-danger buttonCancelCamera" (click)="cancelCapture()">Cancelar</button>
            <button mat-button mat-dialog-close>Cerrar</button>
          </div>
          <!-- <button class="btn btn-success" (click)="saveImage()">Guardar</button> -->
        </ng-template>
      </div>
    </div>
    <!-- <mat-dialog-actions class="mat-dialog-actions-modify">
      <button class="saveState" (click)="saveImage()" mat-raised-button>Guardar</button>
      <button mat-button mat-dialog-close>Cerrar</button>
    </mat-dialog-actions> -->
  </div>
</mat-dialog-content>