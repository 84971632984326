<div class="row staticHeaderMenu">
    <div class="d-flex align-items-center">
      <!-- <div *ngFor="let menuItem of userMenu"> -->
      <div>
          <nav>
            <a class="btn-nav" routerLinkActive="router-link-active" [routerLink]="['/deliveries']">Inicio | </a> 
            <a class="btn-nav" routerLinkActive="router-link-active" [routerLink]="['/deliveries']">Entregas</a>
          </nav>
      </div>
    </div>
  </div>
