import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

/**
 * Service for show snackbar.
 */
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private defaultDuration = 4;
  private readonly defaultPanelClass = "snack-bar-panel";
  private readonly deafultHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private readonly deafultVerticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private _snackBar: MatSnackBar) { }

  /**
   * Open a new snackbar with the specified message.
   * @param {string} message : message for be shown
   */
  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: this.deafultHorizontalPosition,
      verticalPosition: this.deafultVerticalPosition,
      panelClass: [this.defaultPanelClass],
      duration: this.defaultDuration * 1000
    });
  }
}
