import { Injectable } from '@angular/core';
import { LoginService } from './login.service';

/**
   * Service contains logic for validate if user has permissions for route navigation.
   */
@Injectable({
  providedIn: 'root'
})
export class RoutePermissionsService {

  constructor(private _loginService: LoginService) { }

  /**
   * Validate if user has access to route based on his roles.
   * @param {string[]} authorizedRoles : route authorized roles for navigate
   * @return {boolean} user has access
   */
  checkUserHasAccess(authorizedRoles: string[]) {
    if(authorizedRoles === null || authorizedRoles === undefined) {
      return true;
    }

    if(authorizedRoles.includes("all")) {
      return true;
    }

    const user = this._loginService.getLoggedUser();
    const isUserAllowed = user?.roles.some(r => authorizedRoles.includes(r));

    return isUserAllowed;
  }
}
