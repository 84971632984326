import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DeliveryInfoService {

  private endpoint = "DeliveryAPI/";
  private deliveryInfoSource = new BehaviorSubject<any>(null);
  deliveryInfo$ = this.deliveryInfoSource.asObservable();

  
  constructor(private http: HttpClient) { }
  
  getDeliveryInfo(id: string): Observable<any> {
    return this.http.get(`${env.url_api}/` + this.endpoint + id);
  }

  setDeliveryInfo(data: any) {
      this.deliveryInfoSource.next(data);
  }
}
