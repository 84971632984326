import { FormControl } from "@angular/forms";
import { Delivery } from "./delivery.model";


export class OrdersFilter {
    name: string;
    type: OrdersFilterType;
    control: FormControl;
    label: string;
    icoClass: string;
    validValues: any[];
    filterFunction: (delivery: Delivery[], filter: any) => Delivery[];

    constructor(name:string, type:  OrdersFilterType, control: FormControl, label: string, icoClass: string, validValues: any[], ) {
        this.name = name;
        this.type = type;
        this.control = control;
        this.label = label;
        this.icoClass = icoClass;
        this.validValues = validValues;
        this.filterFunction = (delivery, filter) => delivery;
    }
}


export enum OrdersFilterType {
    Select,
    Date
}

