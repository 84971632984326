import { Component, EventEmitter, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

// import { Delivery } from '../../interfaces/deliveries';
import { Delivery } from '../../models/delivery.model';
import { DeliveryService } from '../../services/deliveries.service';
import { CommonResponse } from 'src/app/models/http/response.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { UserService } from 'src/app/users/services/user.service';
import { Router } from '@angular/router';
import { TimerHelper } from 'src/app/shared/helpers/timer.helper';
import { DeliveryInfoService } from '../../services/delivery-info.service';
import { DeliveryInfo } from '../../models/delivery-info';
import Swal from 'sweetalert2';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { DeliveriesTablesHelper } from '../../helpers/deliveries-tables.helper';
import { ObjectsHelper } from '../../helpers/objects.helper';
import { DeliveryAttachedFiles } from '../../models/delivery-attached-files';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { DeliveryAttachedFilesService } from '../../services/delivery-attached-files.service';
import { DataExport } from '../../models/DataExport.models';


@Component({
  selector: 'app-deliveries-table',
  templateUrl: './deliveries-table.component.html',
  styleUrls: ['./deliveries-table.component.scss']
})
export class DeliveriesTableComponent implements AfterViewInit, OnInit {

  readonly getDeliveriesObserver = {
    next: (data: CommonResponse<any>) => this.getDeliveriesNext(data),
    error: (errorStatusCode: number) => this.getDeliveriesError(errorStatusCode),
    complete: () => this.getDeliveriesComplete()
  };

  deliveriesTableLength = 0;
  currentDeliveries: Delivery[] = [];
  deliveryInfoResponse: DeliveryInfo[] = [];
  dataExport: DataExport[] = [];
  _completedDeliveriesInformationUpdating: boolean = false;
  _milisecondsSleepTimeInOrdersBringing: number = 3500;
  completedUpdate!: boolean;
  deliveriesUpdateOperationsTimers: any[] = [];
  private readonly USER_SESSION_KEY = "logged_user";
  isAdmin: boolean = false;
  arrayImage: DeliveryAttachedFiles[] | undefined = [];

  displayedColumns: string[] = ['deliveryNumber', 'salesOrder', 'custPoNum' ,'institutionCode', 'institutionName', 'remisionGuideNumber', 'statusLog', 'status', 'options'];
  dataSource = new MatTableDataSource<Delivery>();
  @Output() DataExportEvent = new EventEmitter<any>();
  @Output() isReadyEvent = new EventEmitter<Boolean>();
  @Output() deliveriesUpdated = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private _deliveryService: DeliveryService,
    private _router: Router,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackBarService,
    private _userService: UserService,
    private _deliveryInfoService: DeliveryInfoService,
    private _deliveryAttachedFilesService: DeliveryAttachedFilesService
  ) {

    this._deliveryService.getListDeliveries()
      .subscribe(this.getDeliveriesObserver);
    this._pageLoadingService.showLoadingGif();
  }

  ngOnDestroy(): void {
    this.deliveriesUpdateOperationsTimers.forEach(t => clearTimeout(t));
  }

  ngOnInit() {
    const user = sessionStorage.getItem(this.USER_SESSION_KEY);

    if (user) {
      const userLogged = JSON.parse(user);
      this.isAdmin = userLogged.roles.some((r: string) => r === "Admin");

    }

    this.dataSource.filterPredicate = function (delivery, filter) {
      const orderAsString = ObjectsHelper.mapObjectValuesToString(delivery).trim().toLowerCase();

      return orderAsString.includes(filter.trim().toLowerCase());
    };

    this._deliveryAttachedFilesService.getImages$().subscribe(image => {
      this.arrayImage = image;
    });
  }

  ngAfterViewInit(): void {

    this.dataSource.paginator = this.paginator;
    DeliveriesTablesHelper.configurePaginatorLabels(this.paginator);
  }

  getDeliveriesNext(data: CommonResponse<any>) {
    const deliveriesResponse: any[] = data.data;
    const deliveries: any[] = [];

    deliveriesResponse.forEach((e) => {
      deliveries.push({
        deliveryNumber: e.deliveryNumber,
        salesOrder: e.deliveryCache?.salesOrder,
        custPoNum: e.deliveryCache?.custPoNum,
        institutionCode: e.deliveryCache?.institutionCode.substring(4),
        institutionName: e.deliveryCache?.institutionName,
        remisionGuideNumber: e.remisionGuideNumber,
        statusLog: e.statusLog,
        status: e.statusLog?.status,
        deliveryAttachedFiles: e.deliveryAttachedFiles?.binaryData
      });
    })

    this.deliveriesTableLength = deliveries.length;
    this.dataSource.data = deliveries;


    this.dataExport = deliveriesResponse.map(e => ({
      statusUpdateTime: e.statusLog?.statusUpdateTime,
      deliveryNumber: e.deliveryNumber,
      remisionGuideNumber: e.remisionGuideNumber,
      institutionCode: e.deliveryCache?.institutionCode,
      institutionName: e.deliveryCache?.institutionName,
      shipTo: e.deliveryCache?.shipTo,
      shipToDescription: e.deliveryCache?.shipToDescrition,
      salesOrder: e.deliveryCache?.salesOrder,
      custPoNum: e.deliveryCache?.custPoNum,
      name: e.statusLog?.receiver?.name,
      phoneNumber: e.statusLog?.receiver?.contact?.phoneNumber,
      eventName: e.statusLog?.status?.eventName,
      pod: e.deliveryAttachedFiles?.binaryData
    }));

    const deliveriesArray1 = deliveries;
    this.currentDeliveries = deliveriesArray1;
    const deliveriesArray = this.dataExport;
    this.deliveriesUpdated.emit();

    // this.updateDeliveriesInformation();

    setTimeout(() => {
      this.isReadyEvent.emit(true);
      this.DataExportEvent.emit({ deliveriesArray });
    }, 1000);

    this._pageLoadingService.showLoadingGif();
  }

  deliveryInfo(deliveryNumber: string) {
    this._pageLoadingService.showLoadingGif();

    this._deliveryInfoService.getDeliveryInfo(deliveryNumber).subscribe(data => {
      this.deliveryInfoResponse = data;

      //Enviar informacion al get-deliveries
      this._deliveryInfoService.setDeliveryInfo(data);
      
      this._router.navigate(['/deliveries/edit']);

    }, error => {
      console.log(error);
      // this._pageLoadingService.hideLoadingGif();
      this._router.navigate(['/deliveries']);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No fue posible encontrar la información de la entrega',
        confirmButtonColor: '#012864',
      })
    })

  }

  getDeliveriesError(errorStatusCode: number) {

    this._pageLoadingService.hideLoadingGif();

    if (errorStatusCode == 404) {
      this._snackbarService.openSnackBar("No se han econtrado deliveries.");
    }
    else {
      const defaultRoute: string[] | undefined = this._userService.getErrorCaseUrlForReturn() ?? ['/deliveries'];
      this._router.navigate(defaultRoute);
      this._snackbarService.openSnackBar("Ha ocurrido un error obteniendo las entregas.");
    }
  }

  getDeliveriesComplete() {
    TimerHelper.sleep(3500)
      .then(() => {
        this._pageLoadingService.hideLoadingGif();
      });
  }

  pageIndexUpdated(event: PageEvent) {

    /*When the delivery table page index changed, please wait a couple of seconds if
        The delivery information has not been fully updated.
        Please allow time for order information to update.
    */
    if (!this._completedDeliveriesInformationUpdating) {
      this._pageLoadingService.showLoadingGif();

      TimerHelper.sleep(this._milisecondsSleepTimeInOrdersBringing);

      setTimeout(() => {
        this._pageLoadingService.hideLoadingGif();
        return event;
      }, 2000);
    }

    return event;
  }

  downloadPDF(deliveryNumber: string) {
    this._deliveryAttachedFilesService.downloadPdf(deliveryNumber)
      .subscribe((result) => {
        if (result.error) {
          // Maneja el error aquí, por ejemplo, muestra un mensaje de error al usuario.
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible descargar el pdf porque la entrega no cuenta con archivos de soporte',
            confirmButtonColor: '#012864',
          })
          console.error('Error al descargar el PDF:', result.error);
        } else {
          // Maneja la descarga exitosa aquí, por ejemplo, abrir el PDF en una nueva ventana.
          const pdfBlob = result.blob;
          if (pdfBlob) {
            const pdfUrl = window.URL.createObjectURL(pdfBlob);

            //Crea un enlace invisible y simula un clic para descargar el PDF
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = `${deliveryNumber}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            window.open(pdfUrl, '_blank');
          } else {
            console.error('El archivo PDF está vacío');
          }
        }
      });
  }
}
