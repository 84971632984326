import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/services/login.service';
import { SnackBarService } from '../shared/services/snackbar.service';
import { PageLoadingService } from '../shared/services/page-loading.service';
import { UserService } from '../users/services/user.service';
import { RoutePermissionsService } from '../auth/services/route-permissions.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticatedUserGuard implements CanActivate, CanActivateChild {

  constructor(private _loginService: LoginService,
    private _router: Router,
    private _snackbarService: SnackBarService,
    private _routePermissionService: RoutePermissionsService,
    private _pageLoadingService: PageLoadingService,
    private _userService: UserService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // this._pageLoadingService.hideLoadingGif();

    if (!this._loginService.isLogged()) {
      this._router.navigate(['/auth']);

      if (this._loginService.getLoggedUser() !== null &&
        this._loginService.getLoggedUser() !== undefined) {
        this._snackbarService.openSnackBar("Se ha vencido la sesión.");
      }

      return true;
    }

    if (!this._routePermissionService.checkUserHasAccess(route.data['accessRoles'])) {
      const defaultUserUrl: string[] | undefined = this._userService.getErrorCaseUrlForReturn()?? ['/deliveries'];
      this._router.navigate(defaultUserUrl);
      this._snackbarService.openSnackBar('No tiene permisos para acceder a la ruta solicitada.');
      return true;
    }

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // this._pageLoadingService.hideLoadingGif();

    if (!this._loginService.isLogged()) {
      this._router.navigate(['/auth']);
      return true;
    }

    if (!this._routePermissionService.checkUserHasAccess(route.data['accessRoles'])) {
      const defaultUserUrl: string[] | undefined = this._userService.getErrorCaseUrlForReturn()?? ['/deliveries'];
      this._router.navigate(defaultUserUrl);
      this._snackbarService.openSnackBar('No tiene permisos para acceder a la ruta solicitada.');
      return true;
    }

    return true;
  }


};
