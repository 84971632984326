import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFormComponent } from './auth/components/login-form/login-form.component';
import { OrdersMainViewComponent } from './orders/components/orders-main-view/orders-main-view.component';
import { EditDeliveryComponent } from './orders/components/edit-delivery/edit-delivery.component';
import { EditStateDeliveryComponent } from './orders/components/edit-state-delivery/edit-state-delivery.component';
import { AuthenticatedUserGuard } from './guards/authenticated-user.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    children: [
      {path: '', component: LoginFormComponent, title: 'Ingreso'},
      {path: '**', redirectTo: ''}
    ]
  },
  {
    path: 'deliveries',
    canActivate: [AuthenticatedUserGuard],
    canActivateChild: [AuthenticatedUserGuard],
    children: [
      { path: "", component: OrdersMainViewComponent, title: 'Pedidos', pathMatch: 'full', data: {accessRoles: ['Admin', 'Consultant']}},
      { path: "edit", component: EditDeliveryComponent, title: 'Editar', pathMatch: 'full', data: {accessRoles: ['Admin']}},
      { path: "**", redirectTo: "deliveries" }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
