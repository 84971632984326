import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/services/login.service';
import { CommonResponse } from 'src/app/models/http/response.model';
import { PageLoadingService } from '../../services/page-loading.service';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  userName: string | undefined = "";

  private readonly logoutObserver = {
    next: (data: number) => this.logoutNext(data),
    error: (error: CommonResponse<any>) => this.logoutError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(private _loginService: LoginService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackBarService,
    private _router: Router) {
  }

  isLoggedUser() {
    return this._loginService.isLogged();
  }

  getUserName() {
    let user = this._loginService.getLoggedUser();

    return user?.name || user?.userEmail;
  }

  private logoutNext(status: number) {
    if (status == 204) {
      this._router.navigate(['/auth']);
      this._snackbarService.openSnackBar("Sesión cerrada con éxito.");
    }
  }

  private logoutError(error: CommonResponse<any>) {
    this._snackbarService.openSnackBar("No fué posible cerrar sesión.");
  }

  logout() {
    this._pageLoadingService.showLoadingGif();

    this._loginService.logout()
      .subscribe(this.logoutObserver);
  }
}
