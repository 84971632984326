<div class="container">
  <div class="row">
    <small class="titleNoDelivery">No.Entrega</small>
    <span class="ndelivery">{{ deliveryInfo.deliveryNumber }}</span>
    <hr />
  </div>
  <div class="row">
    <div class="col">
      <div class="institution">
        <div class="icon-institution">
          <i class="bi bi-buildings-fill icon-general-size"></i>
        </div>
        <div class="content-institution">
          <div class="name-institution">
            <span>Institución</span>
            <!-- <p>CL983784</p> -->
            <!-- <p>{{ deliveryInfo.soldTo }}</p> -->
            <p>{{ institutionCodeParse }}</p>
            <!-- <p>Clínica San Juan de Dios</p> -->
            <p>{{ deliveryInfo.soldToName }}</p>
          </div>
          <div class="address-institution">
            <span>Dirección</span>
            <!-- <p>Av. Nicolás Arriola 3250 San Luis 15019</p> -->
            <p>
              {{
                deliveryInfo.nameAddress +
                  " - " +
                  deliveryInfo.postCodeAddress +
                  " - " +
                  deliveryInfo.cityAddress
              }}
            </p>
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div class="col">
      <div class="sap">
        <div class="content-sap col-md-6">
          <div class="icon-institution">
            <i class="bi bi-inbox-fill icon-general-size"></i>
          </div>
          <div>
            <span>Orden SAP</span>
            <!-- <p>A5567567C</p> -->
            <p>{{ deliveryInfo.salesDoc }}</p>
          </div>
        </div>
        <div class="content-sap col-md-6">
          <div class="icon-institution">
            <i class="bi bi-cart-fill icon-general-size"></i>
          </div>
          <div>
            <span>Número de orden</span>
            <!-- <p>AB- 4834902</p> -->
            <p>{{ deliveryInfo.custPoNum }}</p>
          </div>
        </div>
        <div class="content-sap col-md-6">
          <div class="icon-institution">
            <i class="bi bi-clipboard-fill icon-general-size"></i>
          </div>
          <div>
            <span>Tipo de entrega</span>
            <!-- <p>Directa</p> -->
            <p *ngIf="deliveryInfo.docType == 'KA'">Devolución de producto en consignación</p>
            <p *ngIf="deliveryInfo.docType == 'KE'">Facturación de consignación</p>
            <p *ngIf="deliveryInfo.docType == 'OR'">Venta directa</p>
            <p *ngIf="deliveryInfo.docType == 'TA'">Venta directa</p>
            <p *ngIf="deliveryInfo.docType == 'KB'">Reposición de consignación</p>
            <p *ngIf="deliveryInfo.docType == 'ZKB'">Reposición de consigna corta</p>
            <p *ngIf="deliveryInfo.docType == 'ZTKA'">Movimiento de producto entre cuentas</p>
            <p *ngIf="deliveryInfo.docType == 'ZTKB'">Movimiento de producto entre cuentas</p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="support-files">
        <div class="title-support-files">
          <span class=""> Estado de entrega </span>
          <p>Marca el estado de la entrega haciendo clic en el icono</p>
        </div>
        <!-- Section: Timeline -->
        <section class="py-3 section-time-line">
          <ul class="timeline-with-icons">
            <li class="timeline-item title-support-files mb-4" *ngIf="deliveryInfo.overAllStat == 'Completed'">
              <span class="timeline-icon">
                <i class="bi bi-file-earmark-text text-white bi-sm bi-fw"></i>
              </span>
              <span class="fw-bold">Orden generada</span>
            </li>
            <li class="timeline-item title-support-files mb-4" *ngIf="deliveryInfo.delStatus == 'Completely processed'">
              <span class="timeline-icon">
                <i class="bi bi-box text-white bi-sm bi-fw"></i>
              </span>
              <span class="fw-bold">En preparación</span>
            </li>
            <li class="timeline-item title-support-files" *ngFor="let status of deliveryInfo.status; let i = index;">
              <div class="" *ngIf="status.status.statusId != 3">
                <span class="timeline-icon">
                  <i
                    *ngIf="status.status.statusId == 1"
                    class="bi bi-truck text-white bi-sm bi-fw"
                  ></i>
                  <i
                    *ngIf="status.status.statusId == 2"
                    class="bi bi-check-lg text-white bi-sm bi-fw"
                  ></i>
                  <i
                    *ngIf="status.status.statusId == 3"
                    class="bi bi-x-lg text-white"
                  ></i>
                </span>
                <span class="fw-bold">{{ status.status.eventName }}</span>
                <a
                  *ngIf="
                    status.status.statusId == 1 ||
                    status.status.statusId == 2 ||
                    status.status.statusId == 3
                  "
                  (click)="openModal(status, deliveryInfo.invoice, 'edit', i)"
                  data-toggle="tooltip" data-placement="top" title="Editar estado de entrega"
                >
                  <i class="bi bi-pencil-fill icon-edit-modify"></i>
                </a>
                <a
                  *ngIf="
                    status.status.statusId == 1 ||
                    status.status.statusId == 2 ||
                    status.status.statusId == 3
                  "
                  (click)="deleteStatus(status.statusLogId)"
                  data-toggle="tooltip" data-placement="top" title="Eliminar estado de entrega"
                >
                  <i class="bi bi-trash-fill icon-trash-modify"></i>
                </a>
                <mat-accordion>
                  <mat-expansion-panel class="mat-expansion-panel-header-modify">
                    <mat-expansion-panel-header>
                      <mat-panel-title> Fecha y hora</mat-panel-title>
                      <mat-panel-description>
                        {{ status.statusUpdateTime | date : "yyyy-MM-dd HH:mm:ss" }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="col-md-12" *ngIf="deliveryInfo?.salesDoc != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-inbox-fill icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Número de Orden: </p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ deliveryInfo.salesDoc }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.receiver?.name != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-person-lines-fill icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Contacto de entrega/recojo:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.receiver?.name }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.receiverDNI != null">
                      <div class="content-sap-2 col-md-12">
                        <p class="titleInAccordionCustomContact">Número de identificación:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.receiverDNI }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.receiver?.contact?.phoneNumber != null">
                      <div class="content-sap-2 col-md-12">
                        <p class="titleInAccordionCustomContact">Número de contacto:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.receiver?.contact?.phoneNumber }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.delivery?.remisionGuideNumber != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-upc icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Número de guía:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.delivery?.remisionGuideNumber }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.note != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-truck icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Descripción:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.note }}</p>
                      </div>
                    </div>

                  </mat-expansion-panel>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </li>
            <li class="timeline-item title-support-files" *ngFor="let status of deliveryInfo.status">
              <div class="" *ngIf="status.status.statusId == 3">
                <span class="timeline-icon-2">
                  <i *ngIf="status.status.statusId == 3" class="bi bi-x-lg text-white"></i>
                </span>
                <span class="fw-bold">{{ status.status.eventName }}</span>
                <a *ngIf="status.status.statusId == 3" (click)="openModal(status, deliveryInfo.invoice, 'edit', -1)" data-toggle="tooltip" data-placement="top" title="Editar estado de entrega">
                  <i class="bi bi-pencil-fill icon-edit-modify"></i>
                </a>
                <a *ngIf="status.status.statusId == 3" (click)="deleteStatus(status.statusLogId)" data-toggle="tooltip" data-placement="top" title="Eliminar estado de entrega">
                  <i class="bi bi-trash-fill icon-trash-modify"></i>
                </a>
                <mat-accordion>
                  <mat-expansion-panel class="mat-expansion-panel-header-modify">
                    <mat-expansion-panel-header>
                      <mat-panel-title> Fecha y hora</mat-panel-title>
                      <mat-panel-description>
                        {{ status.statusUpdateTime | date : "yyyy-MM-dd HH:mm:ss" }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="col-md-12" *ngIf="deliveryInfo?.salesDoc != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-inbox-fill icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Número de Orden: </p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ deliveryInfo?.salesDoc }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.receiver?.name != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-person-lines-fill icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Contacto de entrega/recojo:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.receiver?.name }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.receiverDNI != null">
                      <div class="content-sap-2 col-md-12">
                        <p class="titleInAccordionCustomContact">Número de identificación:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.receiverDNI }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.receiver?.contact?.phoneNumber != null">
                      <div class="content-sap-2 col-md-12">
                        <p class="titleInAccordionCustomContact">Número de contacto:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.receiver?.contact?.phoneNumber }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.delivery?.remisionGuideNumber != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-upc icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Número de guía:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.delivery?.remisionGuideNumber }}</p>
                      </div>
                    </div>

                    <div class="col-md-12" *ngIf="status?.note != null">
                      <div class="content-sap-2 col-md-12">
                        <div class="icon-institution">
                          <i class="bi bi-truck icon-general-size"></i>
                        </div>
                        <p class="titleInAccordionCustom">Descripción:</p>
                      </div>
                      <div class="col-md-12">
                        <p class="valueInAccordionCustom">{{ status?.note }}</p>
                      </div>
                    </div>

                  </mat-expansion-panel>
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </li>
          </ul>
        </section>
        <div class="row">
          <div class="col">
            <div class="icons-support-files-modify">
              <a
                (click)="openModal(deliveryInfo, '', 'add', -1)"
                class="addStateDelivery"
              >
                <i class="bi bi-plus-circle-fill"></i>
                <span class="">Agregar un estado de entrega</span>
              </a>
            </div>
          </div>
        </div>
        <!-- Section: Timeline -->
      </div>
    </div>
    <div class="col-md-6">
      <div class="support-files">
        <div class="title-support-files">
          <span>Archivos adjuntos</span>
          <p>
            Si desea descargar todas las imágenes almacenadas en un archivo PDF,
            simplemente haga clic en el botón de descarga.
          </p>
        </div>
        <br />
        <div class="row">
          <div class="col icons-support-files text-right">
            <i class="bi bi-camera" (click)="openModalImg()" data-toggle="tooltip" data-placement="top" title="Capturar imagen"></i>
            <i class="bi bi-upload" (click)="fileInput.click()" data-toggle="tooltip" data-placement="top" title="Cargar imagen"></i>
            <input
              type="file"
              accept="image/jpeg,image/png,image/jpg"
              (change)="selectImg($event)"
              #fileInput
              style="display: none"
            />
            <i
              class="bi bi-download"
              (click)="downloadPDF(deliveryInfo.deliveryNumber)"
              data-toggle="tooltip" data-placement="top" title="Descargar archivos de soporte"
            ></i>
          </div>
        </div>
        <hr />

        <div class="row" *ngFor="let data of arrayImage; let last = last">
          <div
            class="support-images"
            *ngIf="data.deliveryNumber == deliveryInfo.deliveryNumber"
          >
            <div class="image-container col-md-6">
              <i class="bi bi-camera-fill bi bi-camera-fill-modify"></i
              ><b *ngIf="data.deliveryFileTypeId == 2"></b>
              <span (click)="openImage(data)" data-toggle="tooltip" data-placement="top" title="Ver imagen">{{ data.fileName }}</span>
            </div>
            <div class="col-md-2">
              <i (click)="deleteFile(data)" class="bi bi-x deleteImage" data-toggle="tooltip" data-placement="top" title="Eliminar imagen"></i>
            </div>
            <br />
          </div>
          <ng-container *ngIf="last && !ejecutarToBucleFinalizate">
            {{ toBucleFinalizate(last) }}
          </ng-container>
        </div>
        <ng-container *ngIf="bucleFinalizado">
          <!-- Ejecuta la función toBucleFinalizate si el bucle ha finalizado -->
          {{ toBucleFinalizate(bucleFinalizado) }}
        </ng-container>
      </div>
    </div>
    <div class="row buttonBack">
      <button (click)="backTo()">Volver</button>
    </div>
  </div>
</div>
