import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { DeliveryAttachedFiles } from '../models/delivery-attached-files';


@Injectable({
  providedIn: 'root'
})
export class DeliveryAttachedFilesService {

  private endpoint = "DeliveryAttachedFiles/"
  private jsonImage: DeliveryAttachedFiles[];

  private jsonImage$: Subject<DeliveryAttachedFiles[]>

  constructor(private http: HttpClient) {
    this.jsonImage = [];

    this.jsonImage$ = new Subject();
  }


  getListDeliveryAttachedFiles(): Observable<any> {
    return this.http.get(`${env.url_api}/` + this.endpoint);
  }

  downloadPdf(deliveryNumber: string): Observable<{ blob: Blob | null, error: string | null }> {

    return this.http.get(`${env.url_api}/` + this.endpoint + "download-pdf/" + deliveryNumber, { responseType: 'blob' })
      .pipe(
        map((blob: Blob) => {
          // Devuelve el Blob y un valor null para indicar que no hay error.
          return { blob, error: null };
        }),
        catchError((error) => {
          // Manejar errores aquí y devuelve un objeto que incluye el error.
          console.error('Error al descargar el PDF:', error);
          return [{ blob: null, error: 'No se pudo descargar el PDF' }];
        })
      );
  }


  initializeImages(arrayImage: DeliveryAttachedFiles[] | undefined, deliveryNumber: string) {
    this.http.get(`${env.url_api}/` + this.endpoint + deliveryNumber).subscribe(response => {
      this.jsonImage = [];
      const newImages: DeliveryAttachedFiles[] = [];

      Object.values(response).forEach(element => {
        if (!arrayImage?.includes(element.fileName)) {
          arrayImage?.push({
            deliveryFileTypeId: element.deliveryFileTypeId,
            fileName: element.fileName,
            deliveryNumber: element.deliveryNumber,
            base64Data: element.binaryData || ""
          });
        }

        if (!this.jsonImage?.includes(element.fileName)) {
          newImages.push({
            deliveryFileTypeId: element.deliveryFileTypeId,
            fileName: element.fileName,
            deliveryNumber: element.deliveryNumber,
            base64Data: element.binaryData || ""
          });
        }
      });

      // Asigna el nuevo arreglo completo
      this.jsonImage = [...this.jsonImage, ...newImages];
      this.jsonImage$.next(this.jsonImage);
    });

  }

  saveDeliveryAttachedFile(deliveryAttachedFiles: DeliveryAttachedFiles): Observable<any> {
    if (!this.jsonImage.includes(deliveryAttachedFiles)) {
      this.jsonImage.push(deliveryAttachedFiles);

      //notificaremos a todos los componentes subscritos con el metodo next 
      this.jsonImage$.next(this.jsonImage);
    }
    return this.http.post(`${env.url_api}/` + this.endpoint, deliveryAttachedFiles);
  }

  /* No nos podemos subcribir al subject entonces creamos un 
  observable que devuelve un array de imagenes*/
  getImages$(): Observable<DeliveryAttachedFiles[]> {
    //Retorna el subject transformado en observable para poder subscribirse a el en el exterior
    return this.jsonImage$.asObservable();
  }

  getDeliveryAttachedFile(id: number): Observable<any> {
    return this.http.get(`${env.url_api}/` + this.endpoint + id);
  }

  //

  deleteDeliveryAttachedFile(fileName: string, deliveryNumber: string): Observable<any> {
    return this.http.delete(`${env.url_api}/` + this.endpoint + fileName + "/" + deliveryNumber).pipe(
      catchError(error => {
        console.error('Error al eliminar la imagen:', error);
        return throwError('No se pudo eliminar la imagen');
      }),
      map(() => {
        this.jsonImage = this.jsonImage.filter(e => e.fileName !== fileName);
        this.jsonImage$.next(this.jsonImage);
      })
    );
  }

  updateDeliveryAttachedFile(id: number, deliveryAttachedFiles: DeliveryAttachedFiles): Observable<any> {
    return this.http.put(`${env.url_api}/` + this.endpoint + id, deliveryAttachedFiles);
  }
}
