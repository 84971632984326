<div class="header-modal-edit-state">
  <i *ngIf="isEdit" class="bi bi-pencil-fill colorIconAction"></i>
  <i *ngIf="isEdit == false" class="bi bi-plus-circle-fill colorIconAction"></i>
  <b class="modalTitleModify">{{ this.headerText }}</b>
  <i class="bi bi-x bi-x-modify" mat-dialog-close></i>
</div>
<h6 class="title-modal-content">{{ this.subTitle }}</h6>
<mat-dialog-content class="prueba" *ngIf="isEdit == false; else templateName">
  <div class="row" [formGroup]="delivery">

    <mat-form-field class="mat-form-field-modify">
      <mat-label>Cambiar estado</mat-label>
      <mat-select (selectionChange)="selectedOption($event)" formControlName="statusId">
        <mat-option *ngFor="let status of status" [value]="status.statusId">{{ status.eventName }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="mat-form-field-modify">
      <mat-label>Número de factura</mat-label>
      <input matInput placeholder="Número de factura" [value]="data[0].invoice" disabled/>
    </mat-form-field>

    <mat-form-field class="mat-form-field-modify">
      <mat-label>Número de entrega</mat-label>
      <input matInput placeholder="Número de entrega" [value]="data[0].deliveryNumber" disabled/>
    </mat-form-field>

    <mat-form-field class="mat-form-field-modify">
      <mat-label>Número de guía</mat-label>
      <input matInput placeholder="Número de guía" formControlName="remisionGuideNumber" maxlength="100"/>
    </mat-form-field>

    <mat-form-field class="mat-form-field-modify" *ngIf="showContactOptions">
      <mat-label>Número de identificación</mat-label>
      <input matInput placeholder="Número de identificación" formControlName="receiverDNI" maxlength="15"/>
    </mat-form-field>

    <mat-form-field class="mat-form-field-modify" *ngIf="showContactOptions">
      <mat-label>Contacto de entrega/recojo</mat-label>
      <input matInput placeholder="Destinatario" formControlName="name" maxlength="100"/>
    </mat-form-field>

    <mat-form-field class="mat-form-field-modify-phoneNumber" *ngIf="showContactOptions">
      <mat-label>Número de contacto</mat-label>
      <div formGroupName="contact">
        <ngx-mat-intl-tel-input
          ngClass="phoneNumberList"
          [enablePlaceholder]="true"
          selectFirstCountry="true"
          [enableSearch]="false"
          name="phoneNumber"
          describedBy="phoneInput"
          formControlName="phoneNumber"
          (countryChanged)="onCountryCodeSelected($event)"
          [onlyCountries]="['pe']"
        ></ngx-mat-intl-tel-input>
      </div>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notas</mat-label>
      <textarea matInput placeholder="Ingresar nota" rows="2" formControlName="note" maxlength="200"></textarea>
    </mat-form-field>

    <mat-dialog-actions class="mat-dialog-actions-modify">
      <button [ngClass]="delivery.valid ? 'saveStateEnable':'saveStateDisable'" mat-raised-button (click)="saveData()" [disabled]="!delivery.valid">
        Guardar
      </button>
      <button mat-button mat-dialog-close>Cancelar</button>
    </mat-dialog-actions>
  </div>


</mat-dialog-content>

<ng-template #templateName>
  <mat-dialog-content>
    <div class="row" [formGroup]="delivery">

      <mat-form-field class="mat-form-field-modify">
        <mat-label>Cambiar estado</mat-label>
        <mat-select (selectionChange)="selectedOption($event)" formControlName="statusId">
          <mat-option *ngFor="let status of status" [value]="status.statusId">{{
            status.eventName
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-form-field-modify">
        <mat-label>Número de factura</mat-label>
        <input matInput placeholder="Número de factura" [value]="data[1]" disabled/>
      </mat-form-field>

      <mat-form-field class="mat-form-field-modify">
        <mat-label>Número de entrega</mat-label>
        <input matInput placeholder="Número de entrega" [value]="data[0].deliveryNumber" disabled/>
      </mat-form-field>

      <mat-form-field class="mat-form-field-modify">
        <mat-label>Número de guía</mat-label>
        <input matInput placeholder="Número de guía" formControlName="remisionGuideNumber" maxlength="100"/>
      </mat-form-field>

      <mat-form-field class="mat-form-field-modify" *ngIf="showContactOptions">
        <mat-label>Número de identificación</mat-label>
        <input matInput placeholder="Número de identificación" formControlName="receiverDNI" maxlength="15"/>
      </mat-form-field>

      <mat-form-field class="mat-form-field-modify" *ngIf="showContactOptions">
        <mat-label>Contacto de entrega/recojo</mat-label>
        <input matInput placeholder="Destinatario" formControlName="name" maxlength="100"/>
      </mat-form-field>

      <mat-form-field class="mat-form-field-modify-phoneNumber" *ngIf="showContactOptions">
        <mat-label>Número de contacto</mat-label>
        <div formGroupName="contact">
          <ngx-mat-intl-tel-input
          ngClass="phoneNumberList"
          [enablePlaceholder]="true"
          selectFirstCountry="true"
          [enableSearch]="false"
          name="phoneNumber"
          describedBy="phoneInput"
          formControlName="phoneNumber"
          (countryChanged)="onCountryCodeSelected($event)"
          [onlyCountries]="['pe']"
        ></ngx-mat-intl-tel-input>
        </div>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Notas</mat-label>
        <textarea matInput placeholder="Ingresar nota" rows="2" formControlName="note" maxlength="200"></textarea>
      </mat-form-field>
    </div>
    <mat-dialog-actions class="mat-dialog-actions-modify">
      <button [ngClass]="formIsValid ? 'saveStateEnable':'saveStateDisable'" [disabled]="delivery.invalid" mat-raised-button (click)="updateData()">
        Guardar
      </button>
      <button mat-button mat-dialog-close>Cancelar</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</ng-template>
