import { Component, HostListener, Inject, Input, Directive, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { Status, StatusLog } from '../../models/delivery.model';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StatusLogService } from '../../services/status-log.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { DeliveryInfoService } from '../../services/delivery-info.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-edit-state-delivery',
  templateUrl: './edit-state-delivery.component.html',
  styleUrls: ['./edit-state-delivery.component.scss']
})
export class EditStateDeliveryComponent implements AfterViewInit {
  headerText: string = "";
  subTitle: string = "";
  selectedValue: string | null = null;
  delivery!: FormGroup;
  deliveryInfo: any;
  isEdit!: boolean;
  selectedStatus!: number;
  remisionGuideNumber!: string;
  formIsValid: boolean = false;
  initialCountryCode: string = 'pe';
  showContactOptions: boolean = false;

  status: Status[] = [
    { statusId: 1, eventName: 'En camino' },
    { statusId: 2, eventName: 'Entregado' },
    { statusId: 3, eventName: 'Rechazado' },
  ];

  @Input('appRealTimeValidation') validations: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _statusLogService: StatusLogService,
    private _pageLoadingService: PageLoadingService,
    private _router: Router,
    private _deliveryInfoService: DeliveryInfoService,
    private _dialog: MatDialog) {

    this.isEdit = this.data[2] !== "add";
    this.headerText = this.isEdit ? "Editar estado de entrega" : "Agregar estado de entrega";
    this.subTitle = this.isEdit ? "Aquí puedes editar los campos o agregar un estado de entrega" : "Aquí puedes agregar un estado de entrega";

    this.initializeVariables();

  }

  getIsoPhoneNumber(dialCode: string): any {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse('+' + dialCode, '');
    const countryCode = phoneUtil.getRegionCodeForNumber(number)?.toLowerCase();

    return countryCode;
  }

  ngOnInit() {
    // Verifica si los campos phoneNumber y name están inicialmente deshabilitados
    const phoneNumberControl = this.delivery.get('contact.phoneNumber');
    const nameControl = this.delivery.get('name');

    const areFieldsInitiallyDisabled = phoneNumberControl?.disabled && nameControl?.disabled;

    // Suscribe a cambios en receiverDNI para ajustar los campos según sea necesario
    this.delivery.get('receiverDNI')?.valueChanges.subscribe(value => {
      if (value !== null) {
        if (areFieldsInitiallyDisabled) {
          this.enablePhoneNumberAndNameControls();
        }
      } else {
        this.disablePhoneNumberAndNameControls();
      }
    });
  }

  ngAfterViewInit() {

    this.formIsValid = this.delivery.status === "VALID";

    this.delivery.get('statusId')?.setValue(this.selectedStatus);

    this.delivery.get('receiverDNI')?.valueChanges.subscribe(value => {
      if (value !== null) {
        this.enablePhoneNumberAndNameControls();
      } else {
        this.disablePhoneNumberAndNameControls();
      }
    });

    this.delivery.valueChanges.subscribe(value => {
      this.formIsValid = this.delivery.status == "VALID";
    });

    console.log(this.delivery.valid);

  }

  enablePhoneNumberAndNameControls() {
    this.delivery.get('contact.phoneNumber')?.enable();
    this.delivery.get('name')?.enable();
  }

  disablePhoneNumberAndNameControls() {
    this.delivery.get('contact.phoneNumber')?.disable();
    this.delivery.get('contact.phoneNumber')?.setValue(null);
    this.delivery.get('name')?.disable();
    this.delivery.get('name')?.setValue(null);
  }

  onCountryCodeSelected(event: any) {
    const countryCodeControl = this.delivery.get('contact.countryCode');
    if (countryCodeControl) {
      countryCodeControl.setValue(event.dialCode); // Establece el indicativo del país en el control
    }
  }

  initializeVariables() {

    this.selectedStatus = this.data[0].statusId;

    if (this.selectedStatus != 1 && this.isEdit) {
      this.showContactOptions = true;
    }

    if (this.data[0].status[0]?.delivery?.remisionGuideNumber != null) {
      this.remisionGuideNumber = this.data[0].status[0]?.delivery?.remisionGuideNumber;
    } else if(this.data[0].delivery?.remisionGuideNumber !== undefined) {
      this.remisionGuideNumber = this.data[0].delivery?.remisionGuideNumber;
    }
    else {
      this.remisionGuideNumber = this.data[0].remissionGuideNumber;
    }

    const dialCode = this.data[0].receiver?.contact?.phoneCountryIndicator || '+51';

    if (this.data[0].receiver?.contact?.phoneNumber) {

      this.initialCountryCode = this.getIsoPhoneNumber(dialCode + this.data[0].receiver?.contact?.phoneNumber);
    }

    this.delivery = this.fb.group({
      statusLogId: [this.isEdit ? this.data[0].statusLogId : null],
      name: [{ value: this.isEdit ? this.data[0].receiver?.name : null, disabled: true }, Validators.compose([Validators.maxLength(100)])],
      receiverDNI: [this.isEdit ? this.data[0].receiverDNI : null, Validators.maxLength(15)],
      contact: this.fb.group({
        phoneNumber: [{ value: this.isEdit ? this.data[0].receiver?.contact?.phoneNumber : null, disabled: true }, Validators.maxLength(20)],
        countryCode: [dialCode],
      }),
      statusId: [this.isEdit ? this.selectedStatus : 0, Validators.required],
      deliveryNumber: [this.data[0].deliveryNumber, Validators.required],
      remisionGuideNumber: [{value: this.remisionGuideNumber != null ? this.remisionGuideNumber : null, validators: Validators.compose([Validators.maxLength(100), Validators.required]), disabled: true}],
      note: [this.isEdit ? this.data[0].note : null, Validators.maxLength(200)],
    });

    this.formIsValid = this.delivery.status === "VALID";

    this.delivery.get('remisionGuideNumber')?.disable();
    this.delivery.get('remisionGuideNumber')?.setValue(this.remisionGuideNumber);

    this.delivery.get('statusId')?.setValue(this.selectedStatus);

    this.delivery.get('receiverDNI')?.valueChanges.subscribe(value => {
      if (value) {
        this.delivery.get('phoneNumber')?.enable();
        this.delivery.get('name')?.enable();
      } else {
        this.delivery.get('phoneNumber')?.disable();
        this.delivery.get('name')?.disable();
      }
    });

    console.log(this.delivery.valid);
  }

  selectedOption(event: any) {
    this.selectedValue = event.value;

    if (event.value != 1) {
      this.showContactOptions = true;
    } else {
      this.showContactOptions = false;
    }

    if (this.isEdit) {
      // Establecer el valor del formControl statusId si estás en modo de edición
      this.delivery.get('statusId')?.setValue(this.selectedValue);
    }
  }

  clearForm() {
    this.delivery.reset();
  }

  isOptionDisabled(statusId: number): boolean {
    return statusId === 2;
  }

  //Actualiza la información
  updateData() {
    this._pageLoadingService.showLoadingGif();
    this._dialog.closeAll();

    var statusIdP = this.delivery.get("statusLogId")?.value;

    //Obtengo el Número de telefono agregado por el usuario
    const phoneNumberControl = this.delivery.get('contact.phoneNumber')?.value;
    let newPhoneNumber = null;
    let countryCode = '';

    if (phoneNumberControl != null) {
      //Obtengo el indicativo
      countryCode = "+" + this.delivery.get('contact.countryCode')?.value;
      //Quito del número de telefono el indicativo
      newPhoneNumber = phoneNumberControl.replace(new RegExp("^(" + countryCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ")"), "");

    }

    const status: StatusLog = {
      statusLogId: this.delivery.get("statusLogId")?.value,
      deliveryNumber: this.data[0].deliveryNumber,
      phoneNumber: this.delivery.get("phoneNumber")?.value != "" ? newPhoneNumber : null,
      phoneCountryIndicator: countryCode,
      statusId: this.delivery.get("statusId")?.value,
      name: this.delivery.get("name")?.value != "" ? this.delivery.get("name")?.value : null,
      receiverDNI: this.delivery.get("receiverDNI")?.value != "" ? this.delivery.get("receiverDNI")?.value : null,
      remisionGuideNumber: this.delivery.get("remisionGuideNumber")?.value,
      note: this.delivery.get("note")?.value,
      statusUpdateTime: new Date,
    }

    this._statusLogService.updateStatusLog(statusIdP, status).subscribe(data => {
      if (data) {
        this._deliveryInfoService.getDeliveryInfo(this.data[0].deliveryNumber).subscribe(data => {
          console.log(data);
          //Enviar informacion al get-deliveries
          this._deliveryInfoService.setDeliveryInfo(data);

          this._router.navigate(['/deliveries/edit']);
          this._pageLoadingService.hideLoadingGif();

        }, error => {
          console.log(error);
          this._pageLoadingService.hideLoadingGif();
          this._router.navigate(['/']);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible encontrar la información de la entrega',
            confirmButtonColor: '#012864',
          })
        })

        Swal.fire({
          icon: 'success',
          title: '¡Excelente!',
          text: 'El estado fue Actualizado de manera exitosa!',
          confirmButtonColor: '#012864',
        });

      }

    }, error => {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No fue posible Actualizar la información del estado de entrega',
        confirmButtonColor: '#012864',
      });
      this._dialog.closeAll();
      this._pageLoadingService.hideLoadingGif();
    })
  }


  // Dentro de tu controlador
  saveData() {
    this._pageLoadingService.showLoadingGif();
    this._dialog.closeAll();

    //Obtengo el Número de telefono agregado por el usuario
    const phoneNumberControl = this.delivery.get('contact.phoneNumber')?.value;
    let newPhoneNumber = null;
    let countryCode = '';

    if (phoneNumberControl != null) {
      //Obtengo el indicativo
      countryCode = "+" + this.delivery.get('contact.countryCode')?.value;
      //Quito del número de telefono el indicativo
      newPhoneNumber = phoneNumberControl.replace(new RegExp("^(" + countryCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ")"), "");

    }

    const status: StatusLog = {
      statusLogId: 0,
      deliveryNumber: this.data[0].deliveryNumber,
      phoneNumber: newPhoneNumber,
      phoneCountryIndicator: countryCode,
      statusId: this.delivery.get("statusId")?.value,
      name: this.delivery.get("name")?.value,
      receiverDNI: this.delivery.get("receiverDNI")?.value,
      remisionGuideNumber: this.delivery.get("remisionGuideNumber")?.value,
      note: this.delivery.get("note")?.value,
      statusUpdateTime: new Date,
    }

    this._statusLogService.createStatusLog(status).subscribe(data => {
      this.clearForm();
      if (data) {
        this._deliveryInfoService.getDeliveryInfo(this.data[0].deliveryNumber).subscribe(data => {
          console.log(data);
          //Enviar informacion al get-deliveries
          this._deliveryInfoService.setDeliveryInfo(data);

          this._router.navigate(['/deliveries/edit']);
          this._pageLoadingService.hideLoadingGif();

        }, error => {
          console.log(error);
          this._pageLoadingService.hideLoadingGif();
          this._router.navigate(['/']);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No fue posible encontrar la información de la entrega',
            confirmButtonColor: '#012864',
          })
        })

        Swal.fire({
          icon: 'success',
          title: '¡Excelente!',
          text: 'El estado fue agregado de manera exitosa!',
          confirmButtonColor: '#012864',
        });

      }
    }, error => {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No fue posible almacenar la información del estado de entrega',
        confirmButtonColor: '#012864',
      });
      this._dialog.closeAll();
      this._pageLoadingService.hideLoadingGif();
    }
    );
  }
}
