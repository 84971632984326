<div class="header-modal-edit-state">
  <i class="bi bi-pencil-fill colorIconAction"></i>
  <b class="modalTitleModify">{{data.imagen.fileName}}</b>
  <i class="bi bi-x bi-x-modify " mat-dialog-close></i>
</div>
<mat-dialog-content class="mat-dialog-content-modify">
  <div class="imageContainer">
    <img [src]="imgFile" alt="Imagen Modal"/>
  </div>
</mat-dialog-content>
