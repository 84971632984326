import { Component, Input, OnInit } from '@angular/core';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { DeliveryService } from '../../services/deliveries.service';
import { DataExport } from '../../models/DataExport.models';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit {
  @Input() dataExportArray: DataExport | undefined;
  @Input() isReady: Boolean = false;

  readonly generateExcelObserver = {
    error: (error: number) => this.generateDataExportError(error)
  };

  constructor(
    private _snackbarService: SnackBarService,
    private _deliveryService: DeliveryService,
    private _pageLoadingService: PageLoadingService
  ) { }

  ngOnInit(): void {
  }

  formatCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Agrega ceros iniciales si es necesario
    const day = currentDate.getDate().toString().padStart(2, '0'); // Agrega ceros iniciales si es necesario

    return `${day}${month}${year}`;
  }

  generateDataExport() {
    this._pageLoadingService.showLoadingGif();
    this._deliveryService.generateDataExport(this.dataExportArray).subscribe(data => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      const currentDate = this.formatCurrentDate();
      const baseName = "reporte_entregas_";
      const name = baseName + currentDate + ".xlsx";
      link.href = url;
      link.setAttribute('download', `${name}`);
      document.body.appendChild(link);
      link.click();
      this._pageLoadingService.hideLoadingGif();
    });

  }

  generateDataExportError(error: number) {
    if (error == 400) {
      this._snackbarService.openSnackBar("No se encontraron materiales registrados.");
    }
    else {
      this._snackbarService.openSnackBar("Ha ocurrido un error mapeando los materiales del filtro.");
    }
  }
}
