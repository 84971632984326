import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, NgModel  } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatesHelper, DATE_FORMATS } from 'src/app/shared/helpers/dates.helper';
import { OrdersFilter, OrdersFilterType } from '../../models/orders-filter.model';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import { DELIVERY_TYPES } from '../../models/select-delivery-type.models';
import { Delivery } from '../../models/delivery.model';
import { MatInput } from '@angular/material/input';

const moment = _moment;
@Component({
  selector: 'app-orders-filters',
  templateUrl: './orders-filters.component.html',
  styleUrls: ['./orders-filters.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class OrdersFiltersComponent {

  @Output() searchBarModifiedEvent = new EventEmitter<string>();
  @Output() advancedSearchFiltersModified = new EventEmitter<OrdersFilter[]>();

  filterValue: string = '';

  maxValidDate: Date = new Date();
  filters: OrdersFilter[];

  constructor() {
    this.filters = [{
      name: "orderType",
      control: new FormControl(),
      validValues: DELIVERY_TYPES,
      icoClass: "bi bi-truck base-ico",
      label: "Estado de entrega",
      type: OrdersFilterType.Select,
      filterFunction: (orders: Delivery[], value: string) => {
        return orders.filter(o => o.status?.eventName.toLowerCase() == value.toLocaleLowerCase());
      }
    },
    // {
    //   name: "orderDate",      
    //   control: new FormControl(),     
    //   validValues: [],    
    //   icoClass: "bi bi-calendar-event base-ico",    
    //   label: "Fecha de orden",   
    //   type: OrdersFilterType.Date,     
    //   filterFunction: (orders: Delivery[], value: Date) => {     
    //       return orders.filter(o => DatesHelper.getDefaultMomentFormattedString(o.statusLog[0].statusUpdateTime) == DatesHelper.getDefaultMomentFormattedString(value));    
    //   }
    // },
    {
      name: "orderDeliveryDate",
      control: new FormControl(),
      validValues: [],
      icoClass: "bi bi-calendar-event base-ico",
      label: "Fecha de entrega",
      type: OrdersFilterType.Date,
      filterFunction: (orders: Delivery[], value: Date) => {
        return orders.filter(o => DatesHelper.getDefaultMomentFormattedString(o.statusLog?.statusUpdateTime) == DatesHelper.getDefaultMomentFormattedString(value));
      }
    }
    ];

    this.filters.forEach((f) => {
      f.control.valueChanges.subscribe(newValue => {
        if (f.control.valid) {
          this.advancedSearchFiltersModified.emit(this.filters);
        }
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.searchBarModifiedEvent.emit(this.filterValue);
  }

  isSelectType(type: OrdersFilterType) {
    return type == OrdersFilterType.Select;
  }

  isDateType(type: OrdersFilterType) {
    return type == OrdersFilterType.Date;
  }

  cleanFilter(event: Event) {
    this.filters.forEach((f) => {
      f.control.setValue(undefined);
      this.advancedSearchFiltersModified.emit(this.filters);

      this.filterValue = ''; // Borra el texto del input
      this.searchBarModifiedEvent.emit(''); // Resetear el filtro
    });


  }
}
