import { MatPaginator } from "@angular/material/paginator";

/**
 * Helper with order operations for angular material tables.
 */
export class DeliveriesTablesHelper{

    /**
     * Generate paginator label in spanish language according to paginator config.
     * @param {MatPaginator} paginator : paginator configuration
     * @return {string} paginator label in spanish
     */
    static configurePaginatorLabels(paginator: MatPaginator){

        paginator._intl.itemsPerPageLabel = "Cantidad de items por página: ";

        paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length == 0 || pageSize == 0) { return `0 of ${length}`; }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} de ${length}`;
        }
    }
}
