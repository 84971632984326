import { DatesHelper } from "./dates.helper";

export class ObjectsHelper {

  static mapObjectValuesToString(root: Object) {

    let rootAsString = "";

    if(root != null && root != undefined) {
      Object.values(root).forEach((prop) => {
        if(typeof prop === "object") {
          if(prop instanceof Date) {
            rootAsString += DatesHelper.getDefaultDateFormattedString(prop);
          }
          else {
            rootAsString += this.mapObjectValuesToString(prop);
          }
        }
        else {
          rootAsString += `${prop}`;
        }
      });
    }

    return rootAsString;
  }
}
