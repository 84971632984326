<section id="mainHeader">
    <div class="row bg-bostonBlue">
      <div class="col-md-6">
        <img src="../../../../assets/img/png/boston-white-header-logo.png" class="logo-header">
      </div>
      <div class="col-md-6 text-end" *ngIf="isLoggedUser()">
            <div class="dropdown">
                <div class="row">
                    <a class="user-select-main form-select-sm" type="button" id="userMenuDropdown" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <div class="content-center">
                            <i class="bi bi-person-fill dropdown-items-modify"></i>
                            <span class="dropdown-items-modify">{{getUserName()}}</span>
                            <i class="bi bi-chevron-down menu-arrow"></i>
                        </div>
                    </a>
                    <ul class="dropdown-menu menu-options dropdown-menu-end" aria-labelledby="userMenuDropdown" id="dropdownDisplayed">
                        <div class="col">
                            <li><a class="dropdown-item" (click)="logout()">Salir</a></li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <app-navbar *ngIf="isLoggedUser()"></app-navbar>
</section>
